import { provideFatConfigurable } from '@wakeadmin/components';
import { Message } from 'element-ui';
export function injectFatConfigurations() {
    provideFatConfigurable({
        aImagesProps: {
            action: '/permission/web/permission/sso/client/upload',
            // 从
            filter: (item) => {
                if (item.response) {
                    if (!item.response.success) {
                        const message = item.response.msg ?? item.response.errorMessage;
                        Message.error(message);
                        throw new Error(message);
                    }
                    item.url = item.response.data.url;
                }
            },
        },
    });
}
