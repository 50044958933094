import { render, staticRenderFns } from "./noop.vue?vue&type=template&id=2eceea82&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/172.26.59.72+4873+vue-loader@15.10.1_6sfbhgu6kjcmlhpjwryimz4q4m/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports