'use strict';
import 'promise-polyfill/src/polyfill';
import '@wakeadmin/components/style/index.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '@/dss-common/style/base.css';
import '@/dss-common/style/anim.css';
import '@/dss-common/partial/key-header/tpl.less';
// import '@/dss-common/partial/key-header/console.css';
import '@/dss-common/partial/key-footer/tpl.less';

import '@/dss-common/style/base.less';
import '@/dss-common/style/custom.css';
import '@/dss-common/style/icon2.css';
import '@/dss-common/style/wkt-base.less';

import baseApi from './api';
import filters from '@/dss-common/utils/filters';
import store from '@/dss-common/store/base';
import VuePlain from '@/dss-common/vue-plugins/vue-plain';
import { initial } from '@wakeapp/wakedata-backend';

import ElementUI from 'element-ui';
import Vue from 'vue';
import { plugin } from '@wakeadmin/components';
import { debounce } from '@wakeadmin/utils';
import App from '../modules/manage/App';
import router from '../router';
import { injectFatConfigurations } from './configure-components';

window.Promise = Promise;

// 注册过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(ElementUI);
Vue.use(VuePlain);
Vue.use(plugin);

const gotoLogin = debounce(
  () => {
    const url = encodeURIComponent(window.location.href);
    window.top.location.href = `/login.html?gotoUrl=${url}`;
  },
  5000,
  { leading: true }
);

initial({
  baseURL: '/',
  fetch: window.fetch.bind(window),
  interceptor: async (request, next) => {
    const response = await next();

    // 会话失效
    if (!response.success && response.errorCode === 401) {
      gotoLogin();
    }

    return response;
  },
});

// baseApi.getAccountList().then(res => {
//
// }).catch(err => {
//   console.log(err);
// });

baseApi.checklogin(true, true).done((res) => {
  store.commit('set_userInfo', res.data);
  new Vue({
    router,
    store,
    setup() {
      injectFatConfigurations();
    },
    render: (h) => h(App),
  }).$mount('#app');
});
