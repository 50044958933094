import Vue from 'vue';
import Router from 'vue-router';
import ElSearchTablePagination from 'el-search-table-pagination';
import NoopPage from './noop.vue';

Vue.use(Router);
Vue.use(ElSearchTablePagination);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/permission/interface',
    },
    {
      name: 'interface',
      path: '/permission/interface',
      component: () => import('../modules/manage/permission/interface'),
    },
    {
      name: 'role',
      path: '/permission/role',
      component: () => import(`../modules/manage/permission/role`),
    },
    {
      name: 'enterprise',
      path: '/enterprise',
      component: () => import(`../modules/manage/permission/enterprise`),
    },
    {
      name: 'application',
      path: '/enterprise/application',
      component: () => import(`../modules/manage/permission/enterprise/application`),
    },
    {
      name: 'account',
      path: '/account',
      component: () => import(`../modules/manage/permission/account`),
    },
    {
      name: 'ipaas',
      path: '/ipaas',
      component: () => import(`../modules/manage/permission/ipaas`),
    },
    {
      name: 'menu',
      path: '/permission/menu',
      component: () => import(`../modules/manage/permission/menu`),
    },
    {
      name: 'modular',
      path: '/permission/modular',
      component: () => import(`../modules/manage/permission/modular`),
    },
    {
      name: 'business-scope',
      path: '/permission/business-scope',
      component: () => import(`../modules/manage/permission/business-scope`),
    },
    {
      name: 'industry',
      path: '/permission/industry',
      component: () => import(`../modules/manage/permission/industry`),
    },
    {
      name: 'platform-scope',
      path: '/permission/platform-scope',
      component: () => import(`../modules/manage/permission/platform-scope`),
    },
    {
      name: 'dimension',
      path: '/permission/dimension',
      component: () => import(`../modules/manage/permission/dimension`),
    },
    {
      name: 'roleLevel',
      path: '/permission/roleLevel',
      component: () => import(`../modules/manage/permission/roleLevel`),
    },
    {
      name: 'appClassify',
      path: '/permission/appClassify',
      component: () => import(`../modules/manage/permission/appClassify`),
    },
    {
      name: 'dictionary',
      path: '/permission/dictionary',
      component: () => import(`../modules/manage/permission/dictionary`),
    },
    {
      name: 'log',
      path: '/log',
      component: () => import(`../modules/manage/permission/log`),
    },
    {
      name: 'enterpriseMag',
      path: '/enterpriseMag',
      component: () => import(`../modules/manage/enterpriseMag`),
    },
    {
      name: 'brand',
      path: '/brand',
      component: () => import(`../modules/manage/enterpriseMag/brand`),
    },
    {
      name: 'monitor',
      path: '/monitor',
      component: () => import(`../modules/manage/monitor`),
    },
    {
      name: 'device',
      path: '/device',
      component: () => import(`../modules/manage/device`),
    },
    {
      name: 'tag',
      path: '/tag/labelManage',
      component: () => import(`../modules/manage/tag/labelManage`),
    },
    {
      name: 'latitude',
      path: '/tag/latitudeManage',
      component: () => import(`../modules/manage/tag/latitudeManage`),
    },
    {
      name: 'labelMap',
      path: '/tag/labelMap',
      component: () => import(`../modules/manage/tag/labelMap`),
    },
    {
      name: 'lableMapConfig',
      path: '/tag/lableMapConfig',
      component: () => import(`../modules/manage/tag/labelMapConfig`),
    },
    {
      name: 'wx-template',
      path: '/template/wx',
      component: () => import(`../modules/manage/template/wx`),
    },
    {
      name: 'industry-template',
      path: '/template/industry',
      component: () => import(`../modules/manage/template/industry`),
    },
    {
      name: 'seller-template',
      path: '/template/seller',
      component: () => import(`../modules/manage/template/seller`),
    },
    {
      name: 'page',
      path: '/template/industry-template-page',
      component: () => import(`../modules/manage/template/industry-template-page`),
    },
    // {
    //   name: 'notice',
    //   path: '/notice',
    //   component: () => import(`../modules/manage/permission/notice`),
    //   redirect: '/notice/list',
    //   children: [
    //     {
    //       name: 'notice-list',
    //       path: '/notice/list',
    //       component: () => import(`../modules/manage/permission/notice/list`),
    //     },
    //     {
    //       name: 'notice-edit',
    //       path: '/notice/edit',
    //       component: () => import(`../modules/manage/permission/notice/edit`),
    //     },
    //   ],
    // },
    // {
    //   name: 'tenant',
    //   path: '/tenant/list',
    //   component: () => import(`../modules/manage/tenant/list/index.vue`),
    // },
    {
      name: 'event',
      path: '/event',
      redirect: '/event/list',
      component: () => import('../modules/manage/event/index.vue'),
      children: [
        {
          name: 'eventList',
          path: '/event/list',
          component: () => import('../modules/manage/event/list.vue'),
        },
        {
          name: 'eventEdit',
          path: '/event/edit',
          component: () => import('../modules/manage/event/edit.vue'),
        },
      ],
    },
    {
      name: 'sso',
      path: '/sso/list',
      component: NoopPage,
      redirect: '/sso/list',
      children: [
        {
          name: 'ssoList',
          path: '/sso/list',
          component: () => import('../modules/manage/sso/list'),
        },
        {
          name: 'ssoCreateOrEdit',
          path: '/sso/create-or-edit',
          component: () => import('../modules/manage/sso/create-or-edit'),
        },
      ],
    },
    {
      name: 'miniapp',
      path: '/miniapp',
      redirect: '/miniapp/list',
      component: () => import('../modules/manage/miniapp/index.vue'),
      meta: {
        title: '小程序管理',
      },
      children: [
        {
          name: 'miniappList',
          path: '/miniapp/list',
          component: () => import('../modules/manage/miniapp/list.vue'),
          meta: {
            title: '小程序页面',
            keepAlive: true,
          },
        },
        {
          name: 'miniappEdit',
          path: '/miniapp/edit',
          component: () => import('../modules/manage/miniapp/edit.vue'),
          meta: {
            title: '小程序页面编辑',
          },
        },
      ],
    },
  ],
});

export default router;
