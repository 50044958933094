<template>
  <el-container class="manage-app-container">
    <el-aside width="220px" class="side-menu">
      <el-menu router :default-active="activeIndex" :default-openeds="openeds" @select="handleSelect">
        <template v-for="(item, index) in menus">
          <el-submenu v-if="item.childMenu && item.childMenu.length" :key="index" :index="item.url">
            <template slot="title">
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="(child, index) in item.childMenu"
              :key="index"
              :disabled="child.url === ''"
              :index="child.url"
            >
              <template v-if="child.url === ''">
                <el-popover
                  placement="left"
                  width="200"
                  trigger="hover"
                  :content="child.popTitle ? child.popTitle : '需打通会员数据'"
                >
                  <span slot="reference">{{ child.name }}</span>
                </el-popover>
              </template>
              <span v-else>{{ child.name }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :key="index" :index="item.url">
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-main class="container">
      <router-view class="main"></router-view>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Manage',

  data() {
    return {
      menus: [],
      openeds: [],
      activeIndex: '/',
    };
  },

  computed: {
    ...mapState({
      userInfo: 'userInfo',
    }),
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.matched[0].path || '/';
      this.handleSelect(this.activeIndex);
    },
  },
  mounted() {
    if (this.isIE()) {
      // 修复IE顶部菜单点击无法跳转的问题
      window.addEventListener(
        'hashchange',
        () => {
          const currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
    this.getMenus();
    this.activeIndex = this.$route.matched[0].path || '/';
  },

  methods: {
    isIE() {
      return '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    },
    getMenus() {
      console.log('userinfo-->', this.userInfo);
      const menus = [
        {
          url: '/base',
          name: '基础管理',
          childMenu: [
            {
              url: '/permission/dimension',
              name: '维度管理',
            },
            {
              url: '/permission/appClassify',
              name: '终端管理',
            },
          ],
        },
        {
          url: '/resource',
          name: '资源管理',
          childMenu: [
            {
              url: '/permission/interface',
              name: '接口管理',
            },
            {
              url: '/permission/menu',
              name: '菜单管理',
            },
            {
              url: '/permission/role',
              name: '角色管理',
            },
            {
              url: '/permission/roleLevel',
              name: '角色类型管理',
            },
            {
              url: '/permission/modular',
              name: '模块管理',
            },
            {
              url: '/permission/dictionary',
              name: '数据字典',
            },
          ],
        },
        {
          url: '/tenement',
          name: '租户管理',
          childMenu: [
            {
              url: '/enterprise',
              name: '企业管理',
            },
            // {
            //   url: '/tenant/list',
            //   name: '租户管理',
            // },
            // {
            //   url: '/notice/list',
            //   name: '消息通知',
            // },
          ],
        },
        {
          url: '/setting',
          name: '系统设置',
          childMenu: [
            {
              url: '/account',
              name: '账号管理',
            },
            {
              url: '/ipaas',
              name: 'IPAAS账号管理',
            },
            {
              url: '/log',
              name: '日志管理',
            },
          ],
        },
        {
          url: '/sso',
          name: 'SSO 应用管理',
          childMenu: [
            {
              name: '应用管理',
              url: '/sso/list',
            },
          ],
        },
        {
          url: '/event',
          name: '事件中心',
          childMenu: [
            {
              name: '事件中心',
              url: '/event/list',
            },
          ],
        },
        {
          url: '/miniapp',
          name: '小程序管理',
          childMenu: [
            {
              name: '小程序页面',
              url: '/miniapp/list',
            },
          ],
        },
      ];
      const menusChild = [
        // {
        //   url: '/permission/industry',
        //   name: '行业管理',
        // },
        {
          url: '/permission/business-scope',
          name: '业务域管理',
        },
        {
          url: '/permission/platform-scope',
          name: '平台域管理',
        },
      ];
      const adminMenus = [
        {
          url: '/enterpriseMag',
          name: '企业管理',
          childMenu: [
            {
              url: '/enterpriseMag',
              name: '企业管理',
            },
            {
              url: '/brand',
              name: '品牌管理',
            },
          ],
        },
        {
          url: '/monitor',
          name: '监控管理',
        },
        {
          url: '/device',
          name: '设备管理',
        },
        {
          url: '/tag',
          name: '标签管理',
          childMenu: [
            {
              url: '/tag/labelManage',
              name: '标签管理',
            },
            {
              url: '/tag/latitudeManage',
              name: '维度管理',
            },
            {
              url: '/tag/labelMap',
              name: '标签映射',
            },
            {
              url: '/tag/labelMapConfig',
              name: '标签映射管理',
            },
          ],
        },
        {
          url: '/template',
          name: '模板管理',
          childMenu: [
            {
              url: '/template/wx',
              name: '微信模板',
            },
            {
              url: '/template/industry',
              name: '行业模板',
            },
            {
              url: '/template/seller',
              name: '用户模板',
            },
            // {
            //   url: '/template/industry/page',
            //   name: '模板页面'
            // }
          ],
        },
      ];

      // 管理员可看此权限
      if (this.userInfo.roleList === 'common_role_admin') {
        adminMenus.forEach((item) => {
          menus.push(item);
        });
      }
      if (this.userInfo.type === 1) {
        menusChild.forEach((item) => {
          menus[0].childMenu.unshift(item);
        });
      }
      menus.forEach((menu) => {
        this.openeds.push(menu.url);
      });
      this.menus = menus;
    },
    handleSelect(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="less">
html {
  overflow-y: hidden;
}

.manage-app-container {
  //高度是全屏-header的高度
  height: calc(100vh - 60px);
  min-width: 1200px;

  .side-menu {
    width: 200px;
  }
  .side-menu::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .side-menu::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
  }
  .side-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .container {
    overflow-x: visible;
    position: relative;
  }
}
</style>
